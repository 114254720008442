import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link } from "react-router-dom"
import isEmpty from "lodash/isEmpty"
import { DateTime } from "luxon"

import Card from "components/shared/Card"
import formatPrice from "shared/formatting/price"
import { formatSessionDate } from "shared/formatting/datetime"
import { useForceAuthEffect } from "components/Login/hooks"
import forwardArrow from "components/Home/assets/forward-arrow.svg"
import styles from "./ParkingSessions.module.scss"

const SessionCard = ({
  session: {
    zone,
    startTime,
    transactionHistory,
    hashid,
    voided: isCancelled,
    rateName
  }
}) => {
  useForceAuthEffect()
  const { timezone } = zone

  return (
    <Link to={`parking-reservation/${hashid}`} className="text-decoration-none">
      <Card className={styles.card}>
        <div className={styles.cardBody}>
          <div>
            <div className={styles.cardHeader}>
              <div>
                {formatSessionDate(startTime, {
                  timezone,
                  format: DateTime.DATE_MED
                })}
              </div>
              {isCancelled && (
                <div
                  className={classNames(
                    styles.cancelledBadge,
                    "badge text-dark mx-2"
                  )}
                >
                  Cancelled
                </div>
              )}
            </div>
            <div className={classNames(styles.cardCopy, "text-muted")}>
              <div className={styles.zoneRateCopy}>
                <div className={styles.street}>{zone.address.street}</div>
                {!isEmpty(rateName) && (
                  <div className={styles.rateName}>• {rateName}</div>
                )}
              </div>
            </div>
          </div>
          <div
            className={classNames(
              styles.cardPrice,
              "d-flex align-items-center h-100 fw-bold"
            )}
          >
            {formatPrice({ value: transactionHistory.total })}
            <img
              src={forwardArrow}
              alt="Forward arrow"
              className={styles.arrowIcon}
            />
          </div>
        </div>
      </Card>
    </Link>
  )
}

SessionCard.propTypes = {
  session: PropTypes.shape({
    hashid: PropTypes.string,
    rateName: PropTypes.string,
    startTime: PropTypes.string,
    voided: PropTypes.bool,
    transactionHistory: PropTypes.shape({
      total: PropTypes.number
    }),
    zone: PropTypes.shape({
      zoneId: PropTypes.string,
      timezone: PropTypes.string,
      address: PropTypes.shape({
        street: PropTypes.string,
        city: PropTypes.string
      })
    })
  })
}

export default SessionCard
