import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { calendarDateRange } from "shared/helpers/calendarDateRange"
import { formatCalendarDate } from "shared/formatting/datetime"
import {
  Datepicker,
  CalendarPrev,
  CalendarNav,
  CalendarNext,
  luxonTimezone
} from "@mobiscroll/react"
import * as luxon from "luxon"

luxonTimezone.luxon = luxon

import "@mobiscroll/react/dist/css/mobiscroll.min.css"
import "./Calendar.scss"

const Calendar = ({ timezone, ...props }) => {
  const [pages, setPages] = useState(window.innerWidth > 600 ? 2 : 1)
  const { minDate, maxDate } = calendarDateRange()

  useEffect(() => {
    const handleResize = () => {
      setPages(window.innerWidth > 600 ? 2 : 1)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  // Set the min and max dates for the calendar
  // using the timezone passed in as a prop
  const minStartDate = formatCalendarDate(minDate, { timezone })
  const maxStartDate = formatCalendarDate(maxDate, { timezone })

  return (
    <Datepicker
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      pages={pages}
      min={minStartDate}
      max={maxStartDate}
      dataTimezone={timezone}
      displayTimezone={timezone}
    />
  )
}

Datepicker.defaultProps = {
  theme: "ios",
  themeVariant: "light",
  display: "inline",
  showOuterDays: false,
  timezonePlugin: luxonTimezone,
  renderCalendarHeader: () => (
    <>
      <CalendarPrev className="custom-prev" />
      <CalendarNav className="custom-nav" />
      <CalendarNext className="custom-next" />
    </>
  )
}

Calendar.propTypes = {
  timezone: PropTypes.string.isRequired
}

export default Calendar
